
import { ISearchFilter } from "@/interfaces/api/v4/search-topic.interface";
import { isFilterActive } from "@/utils/search";
import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters(["extendedSearchFilterFields", "searchFilters", "mdiClose"]),
  },
  methods: {
    ...mapActions(["toggleSearchFilter"]),
  },
})
export default class SearchFilterChips extends Vue {
  extendedSearchFilterFields!: ISearchFilter[];
  searchFilters!: ISearchFilter[];

  get activeSearchFilters(): ISearchFilter[] {
    return this.extendedSearchFilterFields.filter((field) =>
      isFilterActive(this.searchFilters, field)
    );
  }
}
