
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import SearchFilterFacetPanel from "@/components/search/filter/SearchFilterFacetPanel.vue";

interface Data {
  panels: number[];
}

@Component({
  components: {
    SearchFilterFacetPanel,
  },
  computed: {
    ...mapGetters([
      "topEntityTypeFilters",
      "moreEntityTypeFilters",
      "entityStatusFilters",
      "credentialTypeFilters",
    ]),
  },
})
export default class SearchFilterFacetPanels extends Vue {
  data(): Data {
    return {
      panels: [0, 1, 2],
    };
  }
}
