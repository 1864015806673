
import { ISearchFilter } from "@/interfaces/api/v4/search-topic.interface";
import { Component, Prop, Vue } from "vue-property-decorator";
import SearchFilterFacets from "@/components/search/filter/SearchFilterFacets.vue";

interface Data {
  show: boolean;
}

@Component({
  components: {
    SearchFilterFacets,
  },
})
export default class SearchFilterFacetPanel extends Vue {
  @Prop({ default: () => [] }) fields!: ISearchFilter[];
  @Prop({ default: () => [] }) more!: ISearchFilter[];

  data(): Data {
    return {
      show: false,
    };
  }
}
