
import { Component, Vue } from "vue-property-decorator";
import SearchTopicPage from "./page/SearchTopicPage.vue";
import SearchTopicPageNavigation from "./page/SearchTopicPageNavigation.vue";
import SearchFilterResultCount from "./filter/SearchFilterResultCount.vue";
import SearchFilterChips from "./filter/SearchFilterChips.vue";
import SearchFilterFacetPanels from "./filter/SearchFilterFacetPanels.vue";
import { mapGetters } from "vuex";
import { ISearchFilter } from "@/interfaces/api/v4/search-topic.interface";
import { isFilterActive } from "@/utils/search";

@Component({
  components: {
    SearchTopicPage,
    SearchTopicPageNavigation,
    SearchFilterResultCount,
    SearchFilterChips,
    SearchFilterFacetPanels,
  },
  computed: {
    ...mapGetters([
      "searchQuery",
      "extendedSearchFilterFields",
      "searchFilters",
      "pagedSearchTopics",
    ]),
  },
})
export default class SearchResult extends Vue {
  extendedSearchFilterFields!: ISearchFilter[];
  searchFilters!: ISearchFilter[];

  get activeSearchFilters(): ISearchFilter[] {
    return this.extendedSearchFilterFields.filter((field) =>
      isFilterActive(this.searchFilters, field)
    );
  }
}
