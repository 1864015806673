
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import SearchFilterFacetPanels from "./SearchFilterFacetPanels.vue";

interface Data {
  dialog: boolean;
}

@Component({
  components: {
    SearchFilterFacetPanels,
  },
  computed: {
    ...mapGetters([
      "loading",
      "pagedSearchTopics",
      "mdiClose",
      "mdiFilterOutline",
    ]),
  },
})
export default class SearchFilterDialog extends Vue {
  data(): Data {
    return {
      dialog: false,
    };
  }
}
